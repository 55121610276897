import A0 from "./0000.webp";
import A1 from "./0001.webp";
import A2 from "./0002.webp";
import A3 from "./0003.webp";
import A4 from "./0004.webp";
import A5 from "./0005.webp";
import A6 from "./0006.webp";
import A7 from "./0007.webp";
import A8 from "./0008.webp";
import A9 from "./0009.webp";

import A10 from "./0010.webp";
import A11 from "./0011.webp";
import A12 from "./0012.webp";
import A13 from "./0013.webp";
import A14 from "./0014.webp";
import A15 from "./0015.webp";
import A16 from "./0016.webp";
import A17 from "./0017.webp";
import A18 from "./0018.webp";
import A19 from "./0019.webp";

import A20 from "./0020.webp";
import A21 from "./0021.webp";
import A22 from "./0022.webp";
import A23 from "./0023.webp";
import A24 from "./0024.webp";
import A25 from "./0025.webp";
import A26 from "./0026.webp";
import A27 from "./0027.webp";
import A28 from "./0028.webp";
import A29 from "./0029.webp";

import A30 from "./0030.webp";
import A31 from "./0031.webp";
import A32 from "./0032.webp";
import A33 from "./0033.webp";
import A34 from "./0034.webp";
import A35 from "./0035.webp";
import A36 from "./0036.webp";
import A37 from "./0037.webp";
import A38 from "./0038.webp";
import A39 from "./0039.webp";

import A40 from "./0040.webp";
import A41 from "./0041.webp";
import A42 from "./0042.webp";
import A43 from "./0043.webp";
import A44 from "./0044.webp";
import A45 from "./0045.webp";
import A46 from "./0046.webp";
import A47 from "./0047.webp";
import A48 from "./0048.webp";
import A49 from "./0049.webp";

import A50 from "./0050.webp";
import A51 from "./0051.webp";
import A52 from "./0052.webp";
import A53 from "./0053.webp";
import A54 from "./0054.webp";
import A55 from "./0055.webp";
import A56 from "./0056.webp";
import A57 from "./0057.webp";
import A58 from "./0058.webp";
import A59 from "./0059.webp";

import A60 from "./0060.webp";
import A61 from "./0061.webp";
import A62 from "./0062.webp";
import A63 from "./0063.webp";
import A64 from "./0064.webp";
import A65 from "./0065.webp";
import A66 from "./0066.webp";
import A67 from "./0067.webp";
import A68 from "./0068.webp";
import A69 from "./0069.webp";

import A70 from "./0070.webp";
import A71 from "./0071.webp";
import A72 from "./0072.webp";
import A73 from "./0073.webp";
import A74 from "./0074.webp";
import A75 from "./0075.webp";
import A76 from "./0076.webp";
import A77 from "./0077.webp";
import A78 from "./0078.webp";
import A79 from "./0079.webp";

import A80 from "./0080.webp";
import A81 from "./0081.webp";
import A82 from "./0082.webp";
import A83 from "./0083.webp";
import A84 from "./0084.webp";
import A85 from "./0085.webp";
import A86 from "./0086.webp";
import A87 from "./0087.webp";
import A88 from "./0088.webp";
import A89 from "./0089.webp";

import A90 from "./0090.webp";
import A91 from "./0091.webp";
import A92 from "./0092.webp";
import A93 from "./0093.webp";
import A94 from "./0094.webp";
import A95 from "./0095.webp";
import A96 from "./0096.webp";
import A97 from "./0097.webp";
import A98 from "./0098.webp";
import A99 from "./0099.webp";





export const AirPods = Array.of(
  A0,
  A1,
  A2,
  A3,
  A4,
  A5,
  A6,
  A7,
  A8,
  A9,

  A10,
  A11,
  A12,
  A13,
  A14,
  A15,
  A16,
  A17,
  A18,
  A19,

  A20,
  A21,
  A22,
  A23,
  A24,
  A25,
  A26,
  A27,
  A28,
  A29,

  A30,
  A31,
  A32,
  A33,
  A34,
  A35,
  A36,
  A37,
  A38,
  A39,

  A40,
  A41,
  A42,
  A43,
  A44,
  A45,
  A46,
  A47,
  A48,
  A49,

  A50,
  A51,
  A52,
  A53,
  A54,
  A55,
  A56,
  A57,
  A58,
  A59,

  A60,
  A61,
  A62,
  A63,
  A64,
  A65,
  A66,
  A67,
  A68,
  A69,

  A70,
  A71,
  A72,
  A73,
  A74,
  A75,
  A76,
  A77,
  A78,
  A79,

  A80,
  A81,
  A82,
  A83,
  A84,
  A85,
  A86,
  A87,
  A88,
  A89,

  A90,
  A91,
  A92,
  A93,
  A94,
  A95,
  A96,
  A97,
  A98,
  A99,

);
